import * as svg from "./svg"

const swift = (props: svg.SVGProps) => {

    const {

        stroke,

        className,

        ...rest

    } = props
    return (
        <a href="https://developer.apple.com/swift/"><svg.SVG className={className} width={props.width} height={props.height} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-1845.5007" y1="1255.6392" x2="-1797.1339" y2="981.3379" gradientTransform="matrix(-1 0 0 -1 -1693.2107 1246.5044)">
                <stop offset="0" stopColor="#FAAE42" />
                <stop offset="1" stopColor="#EF3E31" />
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M56.9,0c1.5,0,139.3,0,141.8,0c6.9,0,13.6,1.1,20.1,3.4c9.4,3.4,17.9,9.4,24.3,17.2c6.5,7.8,10.8,17.4,12.3,27.4c0.6,3.7,0.7,7.4,0.7,11.1c0,3.4,0,123.2,0,128.6c0,3.2,0,6.5,0,9.7c0,4.4-0.2,8.9-1.1,13.2c-2,9.9-6.7,19.2-13.5,26.7c-6.7,7.5-15.5,13.1-25,16.1c-5.8,1.8-11.8,2.6-17.9,2.6c-2.7,0-142.1,0-144.2-0.1c-10.2-0.5-20.3-3.8-28.8-9.5c-8.3-5.6-15.1-13.4-19.5-22.4c-3.8-7.7-5.7-16.3-5.7-24.9c0-2,0-140.2,0-142.2C0.2,48.4,2,40,5.7,32.4c4.3-9,11-16.9,19.3-22.5c8.5-5.8,18.5-9.2,28.7-9.7C54.7,0,55.8,0,56.9,0z" />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="130.6117" y1="4.1363" x2="95.213" y2="204.8927">
                <stop offset="0" stopColor="#E39F3A" />
                <stop offset="1" stopColor="#D33929" />
            </linearGradient>
            <path fill="url(#SVGID_2_)" d="M216,209.4c-0.9-1.4-1.9-2.8-3-4.1c-2.5-3-5.4-5.6-8.6-7.8c-4-2.7-8.7-4.4-13.5-4.6c-3.4-0.2-6.8,0.4-10,1.6c-3.2,1.1-6.3,2.7-9.3,4.3c-3.5,1.8-7,3.6-10.7,5.1c-4.4,1.8-9,3.2-13.7,4.2c-5.9,1.1-11.9,1.5-17.8,1.4c-10.7-0.2-21.4-1.8-31.6-4.8c-9-2.7-17.6-6.4-25.7-11.1c-7.1-4.1-13.7-8.8-19.9-14.1c-5.1-4.4-9.8-9.1-14.2-14.1c-3-3.5-5.9-7.2-8.6-11c-1.1-1.5-2.1-3.1-3-4.7c0,0,0,0,0,0c0,0,0,0,0,0L0,121.2V56.7C0,25.4,25.3,0,56.6,0h50.5l37.4,38c0,0,0,0,0,0c84.4,57.4,57.1,120.7,57.1,120.7S225.6,185.7,216,209.4z" />
            <path fill="#FFFFFF" d="M144.7,38c84.4,57.4,57.1,120.7,57.1,120.7s24,27.1,14.3,50.8c0,0-9.9-16.6-26.5-16.6c-16,0-25.4,16.6-57.6,16.6c-71.7,0-105.6-59.9-105.6-59.9C91,192.1,135.1,162,135.1,162c-29.1-16.9-91-97.7-91-97.7c53.9,45.9,77.2,58,77.2,58c-13.9-11.5-52.9-67.7-52.9-67.7c31.2,31.6,93.2,75.7,93.2,75.7C179.2,81.5,144.7,38,144.7,38z" />

        </svg.SVG>
        </a>

    )
}
export default swift