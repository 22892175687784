import * as svg from "./svg"

const java = (props: svg.SVGProps) => {

	const {

		stroke,

		className,

		...rest

	} = props
	return (
		<a href="https://www.java.com/en/">
			<svg.SVG className={className} p="5px" width={props.width} height={props.height} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...rest}>
				<path fill="#FEE187" d="M246.24,97.489c-22.96,14.785-48.981,31.544-62.929,59.266
	c-24.218,48.371,49.562,101.133,52.713,103.353c0.731,0.524,1.615,0.78,2.483,0.78c0.931,0,1.861-0.292,2.617-0.875
	c1.464-1.12,1.886-3.022,1.003-4.574c-0.27-0.474-37.398-52.211-25.766-80.148c6.556-15.747,18.338-26.32,37.382-40.45
	c17.44-12.952,37.209-27.62,48.774-44.786c25.394-37.78-2.823-74.926-3.119-75.297c-1.123-1.434-3.19-1.96-4.955-1.253
	c-1.768,0.714-2.745,2.471-2.348,4.195c0.063,0.248,5.546,24.809-9.721,50.117C276.08,78.277,262.254,87.184,246.24,97.489
	L246.24,97.489z"/>
				<path fill="#FFC61B" d="M238.507,274.079c-3.684,0-7.2-1.123-10.166-3.247c-2.165-1.526-22.354-16.016-39.186-36.789
	c-30.289-37.382-26.311-65.875-17.64-83.193c15.577-30.961,44.413-49.53,67.582-64.451c14.016-9.019,27.252-17.537,31.97-25.381
	c12.026-19.937,8.316-39.607,8.154-40.425c-1.822-8.056,2.492-16.173,10.276-19.319C291.624,0.422,293.821,0,296.071,0
	c5.34,0,10.468,2.476,13.714,6.626c9.708,12.202,29.431,52.473,3.678,90.786c-12.817,19.024-33.514,34.396-51.775,47.958
	c-17.863,13.254-27.857,22.277-33.15,34.99c-5.761,13.838,8.518,44.566,24.223,67.26c0.512,0.741,0.768,1.183,0.811,1.261
	c4.195,7.364,2.323,16.441-4.435,21.61C246.161,272.792,242.367,274.079,238.507,274.079z M299.269,63.704
	c-1.534,3.592-3.382,7.252-5.6,10.928c-7.877,13.093-22.897,22.76-40.291,33.952c-21.537,13.87-45.951,29.592-58.283,54.101
	c-7.957,15.892-1.075,33.431,9.75,48.545c-4.323-14.197-5.757-28.778-0.665-41.008c7.613-18.288,20.529-30.266,41.699-45.975
	c16.638-12.357,35.408-26.298,45.694-41.564C295.896,76.254,298.247,69.828,299.269,63.704z"/>
				<path fill="#FEE187" d="M356.344,122.923c1.464-1.008,1.897-2.848,1.012-4.318c-0.89-1.467-2.816-2.117-4.535-1.52
	c-3.522,1.224-86.289,30.432-86.289,65.73c0,24.326,11.349,37.184,19.636,46.567c3.254,3.679,6.059,6.856,6.986,9.575
	c2.601,7.823-3.566,21.958-6.161,26.743c-0.747,1.381-0.381,3.046,0.89,4.053c0.685,0.54,1.546,0.815,2.401,0.815
	c0.735,0,1.467-0.197,2.107-0.604c1.422-0.908,34.779-22.497,28.799-48.516c-2.228-9.883-7.54-17.109-12.225-23.484
	c-7.253-9.877-12.493-17.017-4.543-30.19C313.737,152.402,355.919,123.219,356.344,122.923L356.344,122.923z"/>
				<path fill="#FFC61B" d="M290.285,283.761c-3.828,0-7.579-1.293-10.563-3.642c-6.31-5-8.118-13.691-4.334-20.693
	c2.865-5.283,5.361-13.094,5.217-16.138c-0.839-1.229-2.9-3.559-4.319-5.163c-8.587-9.722-22.947-25.981-22.947-55.308
	c0-19.825,16.337-39.051,48.557-57.145c22.024-12.367,44.144-20.194,46.594-21.046c1.814-0.632,3.714-0.954,5.642-0.954
	c5.915,0,11.472,3.097,14.499,8.084c4.484,7.443,2.36,17.103-4.811,22.034c-19.557,13.577-43.078,32.506-48.115,40.818
	c-3.202,5.304-3.394,5.624,3.893,15.545c5.183,7.054,11.627,15.824,14.461,28.39c7.885,34.307-30.203,59.75-34.572,62.537
	C296.735,282.838,293.557,283.761,290.285,283.761z M290.78,165.31c-6.893,6.221-11.057,12.266-11.057,17.507
	c0,19.343,8.676,29.167,16.332,37.834c4.008,4.53,7.799,8.817,9.584,14.049c0.24,0.722,0.443,1.458,0.613,2.203
	c2.042-4.104,3.028-8.382,2.082-12.501c-1.633-7.242-5.692-12.768-9.992-18.618C291.397,196.329,282.083,183.645,290.78,165.31z"/>
				<path fill="#FEE187" d="M402.375,263.108c-17.796,0-34.66,8.91-35.378,9.292c-1.637,0.884,36.601,9.009,40.007,30.48
	c3.016,18.521-32.282,47.491-47.397,57.49c-1.629,1.075-2.266,3.167-1.503,4.973c0.637,1.536,2.141,2.497,3.748,2.497
	c0.288,0,0.573-0.03,0.851-0.096c3.588-0.764,93.109-22.792,84.206-71.79C441.484,265.923,417.253,263.108,402.375,263.108
	L402.375,263.108z"/>
				<g>
					<path fill="#FFC61B" d="M459.892,293.61c-3.601-19.933-16.503-43.694-57.517-43.694c-20.668,0-39.508,9.736-41.643,10.877
		c-1.094,0.591-2.075,1.332-2.942,2.181c-0.489,0.296-0.971,0.612-1.429,0.978c-1.807,1.301-47.504,33.324-128.184,36.218
		c-7.783,0.274-15.343,0.413-22.479,0.413c-60.704,0-84.102-9.993-88.347-15.883c-0.526-0.733-0.524-1.034-0.392-1.463
		c1.699-2.39,13.134-8.819,46.807-11.287c7.266-0.532,12.725-6.854,12.192-14.121c-0.533-7.267-6.872-12.73-14.121-12.192
		c-42.116,3.086-64.316,12.34-69.848,29.068c-2.937,8.758-1.538,17.776,3.946,25.406c5.957,8.266,17.109,14.639,33.331,19.101
		c-3.066,4.48-4.378,9.523-3.913,15.123c0.893,10.61,8.461,18.243,19.04,23.744c-2.514,3.496-4.216,7.661-4.756,12.577
		c-0.917,8.163,1.466,15.874,6.891,22.298c11.329,13.413,35.976,20.532,79.906,23.082c4.958,0.289,9.898,0.437,14.683,0.437
		c52.485,0,88.399-16.725,89.901-17.436c6.585-3.119,9.395-10.985,6.275-17.569c-3.119-6.585-10.986-9.397-17.569-6.275
		c-0.315,0.149-31.992,14.896-78.609,14.896c-4.274,0-8.697-0.132-13.15-0.392c-47.127-2.735-58.556-10.539-61.281-13.766
		c-0.894-1.059-0.908-1.636-0.826-2.365c0.009-0.087,0.021-0.127,0.02-0.127c0.84-1.611,7.839-4.242,15.425-4.442
		c0.061-0.001,0.119-0.012,0.179-0.013c8.005,1.266,15.779,2.041,22.463,2.504c4.591,0.317,9.532,0.478,14.696,0.478
		c51.731,0,116.338-15.952,116.976-16.114c7.052-1.781,11.323-8.932,9.557-15.989c-1.765-7.058-8.924-11.354-15.979-9.601
		c-0.619,0.153-63.18,15.32-110.562,15.32c-4.551,0-8.882-0.14-12.866-0.414c-29.876-2.071-47.662-8.54-52.961-12.594
		c2.198-1.528,7.246-4.17,18.136-6.806c10.567,0.797,22.148,1.202,34.763,1.202c7.436,0,15.309-0.145,23.409-0.43
		c77.766-2.79,125.485-29.889,139.636-39.177c19.619,7.32,24.587,13.574,25.232,17.644c0.241,1.481-1.704,8.463-13.412,20.702
		c-8.961,9.369-20.643,18.637-28.232,23.658c-6.94,4.58-9.625,13.464-6.419,21.042c2.682,6.457,8.935,10.63,15.93,10.63
		c1.299,0,2.594-0.148,3.848-0.441v-0.001C371.468,379.244,471.011,354.81,459.892,293.61z M405.981,336.848
		c11.189-13.494,15.793-25.348,14.052-36.032c-1.671-10.539-8.079-18.509-15.747-24.493c18.276,0.41,26.911,6.87,29.645,21.992
		C436.873,314.511,422.694,327.454,405.981,336.848z"/>
					<path fill="#FFC61B" d="M413.005,423.279c-5.352-4.946-13.698-4.614-18.641,0.735c-7.047,7.625-61.814,28.571-175.882,28.72
		c-0.146-0.004-0.295-0.007-0.443-0.007c-106.999,0-133.131-11.406-139.173-18.192c-1.065-1.199-1.262-2.036-1.24-2.794
		c0.811-0.854,4.684-4.12,18.45-7.022c12.997-2.741,29.187-3.956,40.235-4.632c7.273-0.445,12.807-6.7,12.363-13.972
		c-0.445-7.273-6.684-12.799-13.971-12.363c-38.605,2.36-80.059,7.162-83.245,34.443c-1.029,8.791,1.625,17.04,7.69,23.871
		c6.87,7.716,19.163,15.529,51.148,20.761c11.608,7.101,31.245,18.407,43.913,21.862c1.654,0.451,3.535,1.001,5.659,1.621
		c17.238,5.033,49.323,14.402,103.629,15.545c4.568,0.096,9.117,0.144,13.654,0.144c30.725,0,60.697-2.202,89.271-6.568
		c7.201-1.1,12.148-7.831,11.048-15.033c-1.1-7.203-7.825-12.147-15.033-11.048c-31.295,4.781-64.396,6.843-98.384,6.128
		c-27.14-0.571-48.311-3.33-64.599-6.489c5.803,0.078,11.837,0.12,18.14,0.121c0.15,0.005,0.302,0.008,0.454,0.008
		c0.004,0,0.008,0,0.011,0c110.916-0.094,179.188-19.348,195.683-37.198C418.686,436.57,418.356,428.224,413.005,423.279z"/>
				</g>
			</svg.SVG>

		</a>
	)
}
export default java