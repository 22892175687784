import * as svg from "./svg"

const js = (props: svg.SVGProps) => {

    const {

        stroke,

        className,

        ...rest

    } = props
    return (
        <a href="https://www.javascript.com/"><svg.SVG className={className} p="5px" width={props.width} height={props.height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g fill="none" fillRule="evenodd">
                <rect width="24" height="24" fill="#F1DC50" />
                <path stroke="#333" strokeWidth="2" d="M12,11 C12,15.749205 12,18.4158717 12,19 C12,19.8761925 11.4771235,21 10,21 C7.61461794,21 7.5,19 7.5,19 M20.7899648,13.51604 C20.1898831,12.5053467 19.3944074,12 18.4035378,12 C16.8563489,12 16,13 16,14 C16,15 16.5,16 18.5084196,16.5 C19.7864643,16.8181718 21,17.5 21,19 C21,20.5 19.6845401,21 18.5,21 C16.9861609,21 15.9861609,20.3333333 15.5,19" />
            </g>
        </svg.SVG>

        </a>
    )
}
export default js