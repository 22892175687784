import React from "react";
import Toolbar from "../components/Toolbar";
const NotFoundPage = () => (
    <>
        <Toolbar />
        <h1>Error 404 Page Not Found</h1>
    </>
)

export default NotFoundPage

